export const routeMap = {
  "/account": [],
  "/account/settings": [],
  "/callback": [],
  "/clients": [],
  "/clients/create": [],
  "/clients/:clientId": ["clientId"],
  "/clients/:clientId/contacts/emergency": ["clientId"],
  "/clients/:clientId/contacts/distribution-list": ["clientId"],
  "/clients/:clientId/contacts/emergency/create": ["clientId"],
  "/clients/:clientId/contacts/emergency/:contactId/edit": [
    "clientId",
    "contactId",
  ],
  "/clients/:clientId/contacts/distribution-list/create": ["clientId"],
  "/clients/:clientId/contacts/distribution-list/:distributionListItemId/edit":
    ["clientId", "distributionListItemId"],
  "/clients/:clientId/reports": ["clientId"],
  "/clients/:clientId/reports/:reportId": ["clientId", "reportId"],
  "/clients/:clientId/reports/:reportId/preview": ["clientId", "reportId"],
  "/clients/:clientId/services": ["clientId"],
  "/clients/:clientId/services/:serviceName/users": ["clientId", "serviceName"],
  "/clients/:clientId/services/:serviceName/users/create": [
    "clientId",
    "serviceName",
  ],
  "/clients/:clientId/settings": ["clientId"],
  "/clients/:clientId/users": ["clientId"],
  "/clients/:clientId/users/:userId": ["clientId", "userId"],
  "/clients/:clientId/users/:userId/edit": ["clientId", "userId"],
  "/clients/:clientId/users/create": ["clientId"],
  "/company/apiKeys": [],
  "/company/apiKeys/create": [],
  "/company/settings": [],
  "/company/users": [],
  "/company/users/:userId": ["userId"],
  "/company/users/:userId/edit": ["userId"],
  "/company/users/create": [],
  "/session/sign-in": [],
  "/session/sign-up": [],
  "/session/forgot-password": [],
  "/session/reset-password": [],
  "/contacts/approve": [],
} as const

type TRouteMap = typeof routeMap

export type Routes = {
  [TRoutePath in keyof TRouteMap]: TRouteMap[TRoutePath] extends { length: 0 }
    ? (query?: Record<string, string>) => string
    : (
        params: {
          [TRouteParam in TRouteMap[TRoutePath][number]]: string
        },
        query?: Record<string, string>,
      ) => string
}

export const routes = Object.fromEntries(
  Object.entries(routeMap).map(([path, paramNames]) => [
    path,
    paramNames.length > 0
      ? (
          params: Record<(typeof paramNames)[number], string>,
          query?: Record<string, string>,
        ) => {
          const qs = new URLSearchParams({
            ...params,
            ...query,
          }).toString()

          return `${path}/${qs ? `?${qs}` : ""}`
        }
      : (query?: Record<string, string>) => {
          const qs = new URLSearchParams(query).toString()

          return `${path}/${qs ? `?${qs}` : ""}`
        },
  ]),
) as Routes

export const HOME_PATH = "/clients"

export const CLIENT_PATH = "/clients/:clientId"

export type RoutePath = keyof Routes

export type PartialRoutes<Route> = Route extends string
  ? Route extends `/${infer Base extends string}/${infer Rest extends string}`
    ?
        | `/${Base}`
        | (Rest extends "" ? never : `/${Base}${PartialRoutes<`/${Rest}`>}`)
    : never
  : never

export type Route = Routes[RoutePath]

export type RouteParams<TRoutePath extends RoutePath> = Parameters<
  Routes[TRoutePath]
>["length"] extends 0 | 1
  ? undefined
  : Parameters<Routes[TRoutePath]>[0]

export const getUrl = <TRoutePath extends RoutePath>(
  path: TRoutePath,
  options: Parameters<Routes[TRoutePath]>[1] extends undefined
    ? {
        query?: Parameters<Routes[TRoutePath]>[0]
      }
    : {
        params: Parameters<Routes[TRoutePath]>[0]
        query?: Parameters<Routes[TRoutePath]>[1]
      },
) => {
  if ("params" in options && options.params) {
    return routes[path](
      options.params,
      options.query,
    ) as `${RoutePath}${`?${string}` | ""}`
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return routes[path](options.query) as `${RoutePath}${`?${string}` | ""}`
}

const pathParamsRegex = /(?<=\/:)\w+/gu

export const getPathParams = (path: string) =>
  (path.match(pathParamsRegex) ?? []) as string[]
